import React, { Component } from "react";
import "./stuff.css";

var totalParacordCost = 0;
var totalHardwareCost = 0;
var projectCost = 0;
var salary = 0;

class Stuff extends Component {

    constructor() {
        super();
        this.state = {
            pieces: 0,
            length: 0,
            perfootcost: '',
            hardwarepieces: 0,
            hardwarecost: 0
        };
    }
    
    updatePieces(event) {
        this.setState({pieces: event.target.value});
        console.log("Pieces = " + event.target.value);
    };

    updateLength(event) {
        this.setState({length: event.target.value});
        console.log("Length = " + event.target.value);
    };

    updatePerFootCost(event) {
        this.setState({perfootcost: event.target.value});
        console.log("Per foot cost = " + event.target.value);
    };

    addParacord() {
        if (this.state.pieces === 0 || this.state.pieces === ''){
            alert("# of pieces used must be > 0");
        }
        else if (this.state.length === 0 || this.state.length === ''){
            alert("Length used must be > 0");
        }
        else if (this.state.perfootcost === 0 || this.state.perfootcost === ''){
            alert("Cost per foot must be > 0");
        }
        else {
            var total = this.state.pieces * this.state.length * this.state.perfootcost;
            total = parseFloat(total.toFixed(2));
            console.log("button click " + this.state.pieces);
            console.log('Total = ' + total);
            console.log("debug'" + this.state.paracorddiv + "'");
            if (this.state.paracorddiv !== undefined){
                var temp = this.state.paracorddiv + '<br>';
                this.setState ({
                    paracorddiv: temp + 'Pieces (' + this.state.pieces + ') Length (' + this.state.length + ' ft.) Per foot cost (' + this.state.perfootcost + 
                    ')  <b>Total = '+ total + '</b>'
                })
            }
            else{
                this.setState ({
                    paracorddiv: 'Pieces (' + this.state.pieces + ') Length (' + this.state.length + ' ft.) Per foot cost (' + this.state.perfootcost + 
                    ')  <b>Total = '+ total + '</b>'
                })
            }
    
            totalParacordCost = totalParacordCost + this.state.pieces * this.state.length * this.state.perfootcost;
            projectCost = projectCost + total;
            projectCost = parseFloat(projectCost.toFixed(2));
            this.setState({outputTotalCost: projectCost});    //totalParacordCost
    
            //clear inport for next add
            this.setState({pieces: 0})
            this.setState({length: 0})
            this.setState({perfootcost: 0})
        }
    }

    updateHardwareUsed(event) {
        this.setState({hardwareused: event.target.value});
        console.log("Hardware used = " + event.target.value);
    };

    updateHardwarePieces(event) {
        this.setState({hardwarepieces: event.target.value});
        console.log("Hardware Pieces = " + event.target.value);
    };

    updateHardwareCost(event) {
        this.setState({hardwarecost: event.target.value});
        console.log("Hardware cost = " + event.target.value);
    };

    updateHours(event) {
        this.setState({hours: event.target.value});
    }

    updateLaborCost(event) {
        this.setState({laborcost: event.target.value})
    }

    addLabor() {
        // just add to total no records shown
        if (this.state.hours === 0 || this.state.hours === ''){
            alert("Enter hours spent to make this project.")
        }
        else if (this.state.laborcost === 0 || this.state.laborcost === ''){
            alert("Enter your hourly rate.")
        }
        else {
            salary = this.state.hours * this.state.laborcost;
            salary = parseFloat(salary.toFixed(2));
            projectCost = projectCost + salary;
            projectCost = parseFloat(projectCost.toFixed(2));
            this.setState({outputTotalCost: projectCost});

            // This will disable all the children of the div
            var nodes = document.getElementById("divLabor").getElementsByTagName('*');
            for(var i = 0; i < nodes.length; i++){
                nodes[i].disabled = true;
            }
        }
    }

    clearLaborData(){
        projectCost = projectCost - salary;
        projectCost = parseFloat(projectCost.toFixed(2));
        this.setState({outputTotalCost: projectCost});

        var nodes = document.getElementById("divLabor").getElementsByTagName('*');
        for(var i = 0; i < nodes.length; i++){
            nodes[i].disabled = false;
        }

        this.setState({hours: 0})
        this.setState({laborcost: 0})
        salary = 0;
    }

    addHardware() {
        //var total = this.state.hardwarepieces * this.state.hardwarecost;
        // console.log("button click " + this.state.pieces);
        //console.log('Hardware cost = ' + total);

        if (this.state.hardwareused === 0 || this.state.hardwareused === ''){
            alert("Please enter text for hardware used.");
        }
        else if (this.state.hardwarepieces === 0 || this.state.hardwarepieces === ''){
            alert("Hardware pieces used must be > 0");
        }
        else if (this.state.hardwarecost === 0 || this.state.hardwarecost === ''){
            alert("Hardware cost must be > 0");
        }
        else{
            this.setState ({
                hardwarediv: 'Hardware' + this.state.hardwareused + '# Hardware Pieces = ' + this.state.hardwarepieces + ' Hardware cosr = ' + this.state.hardwarecost
            })
            var total = this.state.hardwarepieces * this.state.hardwarecost;
            total = parseFloat(total.toFixed(2));
            if (this.state.hardwarediv !== undefined){
                var temp = this.state.hardwarediv + '<br>';
                this.setState ({
                    hardwarediv: temp + 'Hardware used (' + this.state.hardwareused + ') # used (' + this.state.hardwarepieces + ') Cost (' + this.state.hardwarecost + 
                    ')  <b>Total = '+ total + '</b>'
                })
            }
            else{
                this.setState ({
                    hardwarediv: 'Hardware used (' + this.state.hardwareused + ') # used (' + this.state.hardwarepieces + ') Cost (' + this.state.hardwarecost + 
                    ')  <b>Total = '+ total + '</b>'
                })
            }
            totalHardwareCost = totalHardwareCost + total
            projectCost = projectCost + total
            projectCost = parseFloat(projectCost.toFixed(2));
            this.setState({outputTotalCost: projectCost});
    
            //clear inport for next add
            this.setState({hardwareused: ''})
            this.setState({hardwarepieces: 0})
            this.setState({hardwarecost: 0})
        }

    }

    clearData(event) {
        totalParacordCost = 0
        totalHardwareCost = 0
        projectCost = 0
        this.setState({paracorddiv: ''})
        this.setState({hardwarediv: ''})
        this.setState({pieces: 0})
        this.setState({length: 0})
        this.setState({perfootcost: 0})
        this.setState({hardwareused: ''})
        this.setState({hardwarepieces: 0})
        this.setState({hardwarecost: 0})
        this.setState({outputTotalCost: 0})           
        
        var nodes = document.getElementById("divLabor").getElementsByTagName('*');
        for(var i = 0; i < nodes.length; i++){
            nodes[i].disabled = false;
        }

        this.setState({hours: 0})
        this.setState({laborcost: 0})
        salary = 0;
    };

    saveData(event) {
        if (typeof this.state.paracorddiv === "undefined" && typeof this.state.hardwarediv === "undefined"){
            alert("There is no data to save at this time.")
        }
        else{
            this._downloadTxtFile()
        }
    };

    _downloadTxtFile = () => {
        var element = document.createElement("a");
        
        var textForFile = "Paracord used:\n" + this.state.paracorddiv + "\n\nOther items used:\n" + this.state.hardwarediv + "\n\nLabor cost " + salary + "\n\nProject Total " + this.state.outputTotalCost;
        textForFile = textForFile.replace(/<b>/gi,'');
        textForFile = textForFile.replace(/<\/b>/gi,'');
        textForFile = textForFile.replace(/undefined/gi,'N/A');
        //var textForFile = "data will go here..."
        // var file = new Blob([document.getElementById('myInput').value], {type: 'text/plain'});
        var file = new Blob([textForFile],{type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "ParacordProject.txt";
        element.click();
    }


  render() {
    return (
        <div>
            <div>
                <h2>Supplies</h2>
            </div>
            <h3 className="topMarginBig">Start by getting the paracord used:</h3>
            <div style={{display: 'inline-block'}} className="fontSizeRAMsmall" > 
                <label>Number of pieces used:</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.pieces}
                    style = {{width: 75}}
                    min=''
                    step='1.00'
                    data-number-to-fixed='0'
                    data-number-stepfactor="100"
                    onChange={this.updatePieces.bind(this)}/>
                <label className="leftMarginMedium">Total length used (in feet):</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.length}
                    style = {{width: 75}}
                    min=''
                    step='0.05'
                    data-number-to-fixed='2'
                    data-number-stepfactor="100"
                    onChange={this.updateLength.bind(this)}/>
                <label className="leftMarginMedium">Paracord cost per foot (in USD):</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField, currency"
                    value={this.state.perfootcost}
                    style = {{width: 75}}
                    min=''
                    step='0.01'
                    data-number-to-fixed='2'
                    data-number-stepfactor="100"
                    onChange={this.updatePerFootCost.bind(this)}/>
                <button className="leftMarginMedium" onClick={ (e) => {this.addParacord(); }}>Add</button>
            </div>

            {/* this is for non html text */}
            {/* <div id="paracorddiv" >{this.state.paracorddiv}</div> */}

            {/* this is for html text */}
            <div id="paracorddiv" className="topMarginSmall" dangerouslySetInnerHTML={{ __html: this.state.paracorddiv }}></div>

            {/* 2nd row for hardware costs */}
            <h3 className="topMarginBig">Now let's get hardware used:</h3>
            <div style={{display: 'inline-block'}} className="fontSizeRAMsmall">
                <label>Hardware used:</label>
                <input type="text"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.hardwareused}
                    style = {{width: 150}}
                    onChange={this.updateHardwareUsed.bind(this)}/>
                <label className="leftMarginMedium" >Number of pieces used:</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.hardwarepieces}
                    style = {{width: 75}}
                    min=''
                    step='1'
                    data-number-to-fixed='0'
                    data-number-stepfactor="100"
                    onChange={this.updateHardwarePieces.bind(this)}/>
                <label className="leftMarginMedium">Cost per piece:</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.hardwarecost}
                    style = {{width: 75}}
                    min=''
                    step='0.01'
                    data-number-to-fixed='2'
                    data-number-stepfactor="100"
                    onChange={this.updateHardwareCost.bind(this)}/>
                <button className="leftMarginMedium" onClick={ (e) => {this.addHardware(); }}>Add</button>
            </div>

            {/* this is for non html text */}
            {/* <div id="hardwarediv" >{this.state.hardwarediv}</div> */}

            {/* this is for html text */}
            <div id="hardwarediv" className="topMarginSmall" dangerouslySetInnerHTML={{ __html: this.state.hardwarediv }}></div>

            {/* row for labor costs */}
            <h3 className="topMarginBig">Larbor cost:</h3>
            <div id="divLabor" style={{display: 'inline-block'}} className="fontSizeRAMsmall">
                <label>Hours to make:</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.hours}
                    style = {{width: 75}}
                    min=''
                    step='0.05'
                    data-number-to-fixed='2'
                    data-number-stepfactor="100"
                    onChange={this.updateHours.bind(this)}/>
                <label className="leftMarginMedium">Hourly rate:</label>
                <input type="number"
                    className="leftMarginSmall, fontSizeInputField"
                    value={this.state.laborcost}
                    style = {{width: 75}}
                    min=''
                    step='0.01'
                    data-number-to-fixed='2'
                    data-number-stepfactor="100"
                    onChange={this.updateLaborCost.bind(this)}/>
                <button className="leftMarginMedium" onClick={ (e) => {this.addLabor(); }}>Add</button>
            </div>

            <h3 className="topMarginBig">Cost of project:</h3>
            <div style={{display: 'inline-block'}} className="fontSizeRAMsmall"> 
                <label><b>Total cost = </b></label>
                <label id="lblTotalCost"><b>{this.state.outputTotalCost}</b></label>
            </div>
            <div id="totalcostdiv" className="topMarginMedium">
                <button className="clearButton" onClick={ (e) => {this.clearData(); }}>Clear All</button>
                <button className="saveButton" onClick={ (e) => {this.saveData(); }}>Save</button>
                <button className="clearButton" onClick={ (e) => {this.clearLaborData(); }}>Clear Labor Cost</button>
            </div>
        </div>
    );
  }
}

export default Stuff;
import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
        <div>
            {/* <h2>HELLO</h2> */}
            <p>Just a sample code page in React-js to estimate the cost of making a paracord project.</p>
        </div>
    );
  }
}
 
export default Home;
import React, { Component } from "react";
 
class Contact extends Component {
  render() {
    return (
      <div>
        <h3>Sample contact page</h3>
        {/* <h2>Just kidding</h2> */}
        {/* <p>The easiest thing to do is post on
            our <a href="http://forum.kirupa.com">forums</a>.
        </p> */}
      </div>
    );
  }
}
 
export default Contact;